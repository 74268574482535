const rental = [
    {
        rentalImg: '/images/rental/BananaBoat.jpg',
        rentalName: 'FUN BANNA BOAT RIDE'
    },

    {
        rentalImg: '/images/rental/Fishing.jpg',
        rentalName: 'FISHING PACKAGE'
    },

    {
        rentalImg: '/images/rental/Jetski.jpg',
        rentalName: 'JETSKI'
    },

    {
        rentalImg: '/images/rental/IslandHopping.jpg',
        rentalName: 'ISLAND HOPPING'
    },

    {
        rentalImg: '/images/rental/SpeedBoatFleet.jpg',
        rentalName: 'SPEED BOAT FLEET'
    },

    {
        rentalImg: '/images/rental/YachtCruise.jpg',
        rentalName: 'YACHT CRUISE'
    },

    {
        rentalImg: './images/rental/SailBoatCruises.jpg',
        rentalName: 'SAIL BOAT CRUISES'
    },

    {
        rentalImg: '/images/rental/Snorkeling.jpg',
        rentalName: 'SNORKELING'
    },

    {
        rentalImg: '/images/rental/Kayak.jpg',
        rentalName: 'KAYAK'
    },

    {
        rentalImg: '/images/rental/PartyBoat.jpg',
        rentalName: 'PARTY BOAT'
    }
]

export default rental;