import React from 'react';
import '../../styles/Contact.scss';
import { Link } from 'react-router-dom';
import {Fade} from 'react-awesome-reveal';

function ContactSection() {
  return (
    <>
        <div className="contact-section">
            <div className="section-text">
                <Fade direction='down' triggerOnce>
                  <h1>Still have a questions? Don't hesitate to contact us.</h1>
                </Fade>

                <div className="contact-btn">
                  <Fade direction='down' triggerOnce>
                    <Link to="/contact"><button>CONTACT US</button></Link>
                  </Fade>
                </div>
            </div>
        </div>
    </>
  )
}

export default ContactSection