import React from 'react';
import DescPage from '../../components/Destination/DestinationSec';

function index() {
  return (
    <>
        <DescPage />
    </>
  )
}

export default index