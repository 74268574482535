import React from 'react';
import Hero from '../../components/Hero/Hero';
import Offer from '../../components/Offer/OfferSection';
import Contact from '../../components/Contact/index';
import Boats from '../../components/Boats/BoatSection';
import AboutSection from '../../components/HeroExt/abtSection';
import DestSection from '../../components/HeroExt/destSection';

function Home() {
  return (
    <>
        <Hero/>
        <DestSection />
        <AboutSection />
        <Offer />
        <Contact/>
        <Boats/>
    </>
  )
}

export default Home