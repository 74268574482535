import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Navigation from './components/Navigation/index';
import Footer from './components/Footer/index';
import About from './pages/About/index';
import Activities from './pages/Activities/index';
import Boats from './pages/Boats/index';
import Contact from './pages/Contact/index';
import Gallery from './pages/Gallery/index';
import Destination from  './pages/Destination/index'
import ScrollToTop from './components/ScrollToTop.';
import Inquiries from './pages/Inquiries/index';
import Member from './pages/Membership/index'

function App() {
  return (
   <>
      <BrowserRouter forceRefresh={true}>
      <Navigation />
      <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/activities" element={<Activities/>} />
          <Route path="/boats" element={<Boats/>} />
          <Route path="/gallery" element={<Gallery/>} />
          <Route path="/destinations" element={<Destination/>} />
          <Route path="/membership" element={<Member/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/inquiry" element={<Inquiries/>} />
        </Routes>
        <Footer/>
      </BrowserRouter>
   </>
  );
}

export default App;
