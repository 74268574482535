import React, {useRef, useState} from 'react';
import '../../styles/inquiry.css';
import {Fade} from 'react-awesome-reveal';
import emailjs from '@emailjs/browser';
import {AiOutlineCheckCircle} from 'react-icons/ai';
import {FiCheckCircle} from 'react-icons/fi';

function Inquiry() {

  //Modal message
  const [modal, setModal] = useState(false);

  const showModal = () => {
      setModal(!modal);
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_n2d2x0r', 'template_frmn3tp', form.current, 'WFxWKyFtVWC1fEKeb')
        .then((result) => {
            // console.log(result.text);
            e.target.reset();
            //reseting the value after submit
            setUname("");
            setUemail("");
            setUphone("");
            setUdate("");
            setUboat("");
            setUpax("");
            setUmessage("");
            // alert("success");
        }, (error) => {
            // console.log(error.text);
        });

  };

   //form value
    const [uname, setUname] = useState("");
    const [uemail, setUemail] = useState("");
    const [uphone, setUphone] = useState("");
    const [udate, setUdate] = useState("");
    const [uboat, setUboat] = useState("");
    const [upax, setUpax] = useState("");
    const [umessage, setUmessage] = useState("");

    //check if input form is filled
    const validate = () => {
        return uname.length && uemail.length && uphone.length && udate.length && uboat.length && upax.length && umessage.length ;
    };

    //disable the button if the form is invali and enable if valid or filled
    //reset form

  return (
    <>
      {/** SUBMIT MODAL */}

      <div className={modal ? 'modal-wrap active' : 'modal-wrap'}>
        <div className="modal-box">
          <AiOutlineCheckCircle className='checkIcon' />
            <h1>Thank You!</h1>
            <p>Your details has been successfully <br/> submitted.</p>

            <div className="exit-btn">
                <button onClick={showModal} >OK</button>
            </div>
        </div>
      </div>

        <Fade triggerOnce>
          <div className="inq-main">
              <div className="inq-wrap">
                <div className="inq-col">
                  <div className="inq-text">
                    <h1>Find your next adventure</h1>
                    <p>Book now and get strapped in for a better adventure
                      experience in your life-time. Just look for opportunity to be with nature.</p>

                    <div className="list-adv">
                      <ul>
                        <li>
                          <p><FiCheckCircle className='check-icon' /> Tailored Experiences to Your Desires.</p>
                        </li>
                        <li>
                          <p><FiCheckCircle className='check-icon' /> Privacy and Freedom to Explore.</p>
                        </li>
                        <li>
                          <p><FiCheckCircle className='check-icon' /> Personalized Experiences, Your Way.</p>
                        </li>
                        <li>
                          <p><FiCheckCircle className='check-icon' /> Family-Friendly Adventures.</p>
                        </li>
                        <li>
                          <p><FiCheckCircle className='check-icon' /> Thrilling Water Adventures.</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="inq-col form-col">
                  <form ref={form} onSubmit={sendEmail} className="inq-form">
                      <div className="row">
                        <div className="col-md-6 col-12 form-input">
                          <label>Your Name <span>*</span></label>
                          <input type="text" name="user_name" value={uname} onChange={(e) => setUname(e.target.value)} />
                        </div>

                        <div className="col-md-6 col-12 form-input">
                          <label>Your Email <span>*</span></label>
                          <input type="email" name="user_email" value={uemail} onChange={(e) => setUemail(e.target.value)} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-12 form-input">
                          <label>Your Phone <span>*</span></label>
                          <input type="number" name="user_phone" value={uphone} onChange={(e) => setUphone(e.target.value)} />
                        </div>

                        <div className="col-md-6 col-12 form-input">
                          <label>Target Date <span>*</span></label>
                          <input type="date" name="user_date" value={udate} onChange={(e) => setUdate(e.target.value)} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6 col-12 form-input">
                          <label>Type of boat <span>*</span></label> <br></br>
                          <select name="boat_type" value={uboat} onChange={(e) => setUboat(e.target.value)} >
                            <option value="Bayliner Pilothouse">Bayliner Pilothouse</option>
                            <option value="Tiara Damifino">Tiara Damifino</option>
                            <option value="Swiss Banker">Swiss Banker</option>
                            <option value="Angela Claire">Sailboat</option>
                            <option value="Speed Boat">Speed Boat</option>
                          </select>
                        </div>

                        <div className="col-md-6 col-12 form-input">
                          <label>Number of pax <span>*</span></label>
                          <input type="number" name="pax_num" value={upax} onChange={(e) => setUpax(e.target.value)} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12 col-12 form-input-message">
                          <label>Additional Request</label>
                          <textarea name="user_message" id="message" cols="30" rows="5" value={umessage} onChange={(e) => setUmessage(e.target.value)}></textarea>
                        </div>
                      </div>

                      <div className="submit-inquiry">
                        <button type='submit' onClick={showModal} disabled={!validate()} >SUBMIT</button>
                      </div>
                  </form>
                </div>
              </div>
          </div>
        </Fade>
    </>
  )
}

export default Inquiry