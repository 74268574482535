import React from 'react';
import { Link } from 'react-router-dom';
import '../../styles/Nav.css';
import {BiMenu} from 'react-icons/bi';

function Navigation() {

  return (
    <>
    <nav class="navbar navbar-expand-lg sticky-top">
        <Link class="navbar-brand logo" to="/" data-toggle="collapse" data-target=".navbar-collapse.show">
            <img src="/images/logo/marinaLogoNew 1.png" alt="" />
        </Link>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"><BiMenu/></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item active">
                    <Link class="nav-link" to="/" data-toggle="collapse" data-target=".navbar-collapse.show">HOME <span class="sr-only">(current)</span></Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link" to="/about" data-toggle="collapse" data-target=".navbar-collapse.show">ABOUT US</Link>
                </li>
                {/**
                    <li class="nav-item dropdown">
                        <Link class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Dropdown
                        </Link>
                        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <Link class="dropdown-item" href="#">Action</Link>
                        <Link class="dropdown-item" href="#">Another action</Link>
                        <div class="dropdown-divider"></div>
                        <Link class="dropdown-item" href="#">Something else here</Link>
                        </div>
                    </li>
                */}

                <li class="nav-item">
                    <Link class="nav-link" to="/boats" data-toggle="collapse" data-target=".navbar-collapse.show">OUR BOATS</Link>
                </li>

                <li class="nav-item">
                    <Link class="nav-link" to="/gallery" data-toggle="collapse" data-target=".navbar-collapse.show">GALLERY</Link>
                </li>

                <li class="nav-item">
                    <Link class="nav-link" to="/destinations" data-toggle="collapse" data-target=".navbar-collapse.show">DESTINATIONS</Link>
                </li>

                <li class="nav-item">
                    <Link class="nav-link" to="/contact" data-toggle="collapse" data-target=".navbar-collapse.show">CONTACT US</Link>
                </li>

                <form class="form-inline my-2 my-lg-0">
                    <Link to="/inquiry" data-toggle="collapse" data-target=".navbar-collapse.show"><button class="btn my-2 my-sm-0 inquire-btn">INQUIRE NOW</button></Link>
                </form>
            </ul>

            <Link class="navbar-brand-lc logo-lc" to="/">
                <img src="/images/logo/LCLOGOBlue.png" alt="" />
            </Link>
        </div>
    </nav>
    </>
  )
}

export default Navigation