import React from 'react';
import '../../styles/Boats.css';
import '../../styles/Offer.css';
import {RiRuler2Line} from 'react-icons/ri';
import {BsSpeedometer} from 'react-icons/bs';
import {HiOutlineUserGroup} from 'react-icons/hi';
import { Link } from 'react-router-dom';
import {Zoom} from 'react-awesome-reveal';

function BoatSection() {

  return (
    <>
        {/**MAIN */}
        <div className="container-fluid boat-page">
            <div className="boat-tag">

                <Zoom triggerOnce="true"><h3>Come experience all new boats this summer brings.</h3></Zoom>
                <Zoom triggerOnce="true"><p>Choose any boat from our exceptional fleet with confidence, as each vessel is meticulously maintained, fully equipped with modern amenities,
                and crewed by dedicated professionals committed to your comfort and satisfaction.</p></Zoom>

               <Zoom triggerOnce="true">
                    <div className="boat-btn">
                        <Link to="/boats" className='boat-seemore'><button>SEE MORE</button></Link>
                        <Link to="/inquiry" className='boat-book'><button>BOOK NOW</button></Link>
                    </div>
               </Zoom>
            </div>

            <div className="row boat-row">

                    <div className="col-md-4 col-12">
                        <div className="boat-box">
                            <Zoom direction='down' duration={1000} triggerOnce="true">
                                <div className='boat-handler'>
                                    <img src="/images/boat/EndlessSummer2.png" alt="" />
                                </div>
                                <h5>ENDLESS SUMMER</h5>
                                <h4>BAYLINER PILOTHOUSE</h4>
                                <p>Our Bayliner Yacht is a masterpiece of design, elegance, and engineering, from the moment you step on board, you'll be immersed in a world of refined luxury and impeccable craftsmanship. </p>

                                <hr></hr>

                                <div className="spec-details">
                                    <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>54-footer</p></div>
                                    <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>12-15 knots</p></div>
                                    <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>25 pax</p></div>
                                </div>
                            </Zoom>
                        </div>
                    </div>

                    <div className="col-md-4 col-12">
                        <div className="boat-box">
                            <Zoom direction='down' duration={1000} triggerOnce="true">
                                <div className='boat-handler'>
                                    <img src="/images/boat/TIARA DAMAFINO.jpg" alt="" />
                                </div>
                                <h5>
                                    DAMIFINO III
                                </h5>
                                <h4>TIARA 36 OPEN</h4>
                                <p>Prepare to experience yachting excellence like never before with our Tiara Damafino, is a vessel that perfectly embodies the precise blend of performance, and comfort. </p>

                                <hr></hr>

                                <div className="spec-details">
                                    <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>36-footer</p></div>
                                    <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>17-25 knots</p></div>
                                    <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>15 pax</p></div>
                                </div>
                            </Zoom>
                        </div>
                    </div>

                    <div className="col-md-4 col-12">
                        <div className="boat-box">
                            <Zoom direction='down' duration={1000} triggerOnce="true">
                                <div className='boat-handler'>
                                    <img src="/images/boat/SWISS BANKER.png" alt="" />
                                </div>
                                <h5>
                                    SWISS BANKER
                                </h5>
                                <h4>BAYLINER 360</h4>
                                <p>Whether you're fishing, cruising, or simply enjoying a day out with family and friends, the Swiss Banker offers a perfect balance of comfort, functionality, and performance.</p>

                                <hr></hr>

                                <div className="spec-details">
                                    <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>36-footer</p></div>
                                    <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>25-32 knots</p></div>
                                    <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>15 pax</p></div>
                                </div>
                            </Zoom>
                        </div>
                    </div>
            </div>
        </div>
    </>
  )
}

export default BoatSection