import React from 'react'
import { Link } from 'react-router-dom';
import rentalActivities from '../../data/rentalActivities';
import Slider from 'react-slick';
import { Zoom, Slide, } from 'react-awesome-reveal';
import '../../styles/Hero.css';

function destSection() {

    const settings = {
      dots: true,
      infinite: true,
      speed: 800,
      slidesToShow: 4,
      slidesToScroll: 2,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  return (
    <>
        <div className="destContainer">
            <div className="destTitle">
                <Zoom triggerOnce='true'><span>DESTINATION</span></Zoom>
                <Zoom triggerOnce='true'>
                    <div className='wtr-icon'>
                        <img src="/images/logo/wtr.png" alt="" />
                    </div>
                </Zoom>
                <Zoom triggerOnce='true'>
                    <h3>
                    Treat yourself to a private boat experience to get the ultimate marine adventure.
                    </h3>
                </Zoom>
            </div>

            <Slide triggerOnce='true'>
                <div className="carouselActivities">
                    <Slider {...settings}>
                        {rentalActivities.map((items, index) => (
                            <div className='rentalBox'>
                                <img src={items.rentalImg} alt="" />
                                <h1>{items.rentalName}</h1>
                            </div>
                        ))}
                    </Slider>
                </div>
            </Slide>

            <div className="dest-description">
               <Zoom triggerOnce='true'>
                <p>Destination Marina is a known for its picturesque coastal setting and vibrant atmosphere.
                    Located at the heart of Bagac Bataan, Las Casas Filipinas de Acuzar Destination Marina offers a
                    stunning view of the ocean and a range of activities for visitors to enjoy.
                </p>
               </Zoom>
            </div>

            <div className="dest-btn">
                <Zoom triggerOnce='true' direction='down'>
                    <Link to="/destinations"><button>BROWSE DESTINATION</button></Link>
                </Zoom>
            </div>
        </div>
    </>
  )
}

export default destSection