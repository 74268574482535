import React from 'react';
import '../../styles/Footer.css';
import { Link } from 'react-router-dom';
import {AiFillFacebook} from 'react-icons/ai';
import {PiInstagramLogoFill} from 'react-icons/pi';

function index() {
  return (
    <>
        <div className="container-fluid footer-section">
            <div className="row">
                <div className="col-md-4 col-12">
                    <div className="logo-image logo">
                        <img src="/images/logo/marinaLogoNew.png" alt="" />
                    </div>

                    <div className="property-desc">
                        <p>
                            Located at the heart of Bagac Bataan, Las Casas Filipinas de Acuzar offers a stunning view of the ocean and a range of activities for visitors to enjoy.
                        </p>
                    </div>

                    <div className="marina-socials">
                        <Link className='social-icons' to="https://www.facebook.com/profile.php?id=100095051310320"><AiFillFacebook/></Link>
                        <Link className='social-icons' to="https://www.instagram.com/lascasas.marina/"><PiInstagramLogoFill/></Link>
                    </div>
                </div>

                <div className="col-md-8 col-12 row link-row">
                    <div className="col-md-4 col-12">
                        <div className="footer-title">
                            <ul>
                                <p>CONTACT DETAILS</p>
                                {/**<li>+63920-900-9931 or +63916-237-8888</li> */}
                                {/* <li>+63920-900-9931 or +63945-561-2947</li> */}
                                <li>Marina Inquiries: +63963-441-5023</li>
                                <li>Technical Inquiries: +63921-888-8222</li>
                                <li>Mon-Sun, 8:00am until 8:00pm</li>
                                <li>Brgy. Ibaba, Bagac Bataan</li>
                                <li>info@lascasasmarina.com</li>
                                <li>We reply within 24 hrs</li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 col-12">
                        <div className="footer-title">
                            <ul>
                                <p>EXPLORE</p>
                                <li><Link className='shortcuts' to="/about">About Us</Link></li>
                                <li><Link className='shortcuts' to="/boats">Our Boats</Link></li>
                                <li><Link className='shortcuts' to="/gallery">Gallery</Link></li>
                                <li><Link className='shortcuts' to="/destinations">Destinations</Link></li>
                                <li><Link className='shortcuts' to="/membership" target='_blank'>Yacht Particular Form</Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4 col-12">
                        <div className="footer-title">
                            <ul>
                                <p>OUR COMPANY</p>
                                <li><Link className='shortcuts' to="https://mariventhotels.com/" target='_blank'>Marivent Hotels Resort Inc.</Link></li>
                                <li><Link className='shortcuts' to="https://lascasasfilipinas.com/" target='_blank'>Las Casas Bataan</Link></li>
                                <li><Link className='shortcuts' to="https://lascasasqc.com" target='_blank'>Las Casas Quezon City</Link></li>
                                <li><Link className='shortcuts' to="https://vshotel.com.ph/" target='_blank'>VS Hotel</Link></li>
                                <li><Link className='shortcuts' to="https://victoriasportsclub.com/" target='_blank'>Victoria Sports Club</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-md-12">
                <div className="copyright">
                    <p>Copyright © 2024 Las Casas Marina, All rights reserved. </p>
                </div>
            </div>
        </div>
    </>
  )
}

export default index