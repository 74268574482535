import React from 'react'
import '../../styles/About.css';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import { Slide } from 'react-awesome-reveal';

function index() {

  return (
    <>
        <div className="about-main">
          <Fade>
            <div className="about-cover">
              <img src="/images/yacht/banana boat.jpg" alt="" />
              <Fade top cascade>
                <div className="about-text">
                  <Fade direction='down' triggerOnce>
                    <h1>ABOUT</h1>
                  </Fade>
                </div>
              </Fade>
            </div>
          </Fade>

            <div className="value-section container">
              <div className="row vsec">
                  <div className="col-md-6 col-12">
                    <div className="value-sec-a">
                      <Slide duration={2000} triggerOnce>
                        <label>OUR VALUE</label>
                        <h3>Why choose us</h3>
                        <p>Las Casas Marina itself is a hub of activity, we offer exclusive inside access to Candaba Dos,
                        where you can enjoy the breath-taking view, pool area, 3 minutes complimentary massage for four and private bar for purchase and enjoyment. </p>
                        <Link to="/destinations"><button className='about-btn'>EXPLORE NOW</button></Link>
                      </Slide>
                    </div>
                  </div>

                  <div className="col-md-6 col-12">
                    <div className="value-sec-b">
                      <Slide duration={2000} direction='right' triggerOnce >
                        <div className="value-a">
                            <img src="/images/icons/vision.png" alt="" />
                            <div className="value-text">
                              <h3>Our Vision</h3>
                              <p>Our vision is to establish a premier destination where members can gather, connect, and indulge in the timeless elegance and adventurous spirit of yachting.</p>
                            </div>
                        </div>

                        <div className="value-a">
                            <img src="/images/icons/mission.png" alt="" />
                            <div className="value-text">
                              <h3>Our Mission</h3>
                              <p>The mission of our yacht club is to provide an exceptional yachting experience, foster a vibrant community of passionate sailors, and promote a culture of excellence, camaraderie, and environmental stewardship.</p>
                            </div>
                        </div>

                        <div className="value-a">
                            <img src="/images/icons/motto.png" alt="" />
                            <div className="value-text">
                              <h3>Motto</h3>
                              <p>"Sailing Together, Embracing the Boundless Seas, and Creating Lifelong Memories of Friendship and Adventure"</p>
                            </div>
                        </div>
                      </Slide>
                    </div>
                  </div>
              </div>
            </div>

            <div className="story-wrap">
              <div className="story-row">
                <div className="story-col-a">
                  <div className="story-image">
                    <Slide triggerOnce duration={2000}>
                      <img src="/images/coverpage/mock.png" alt="" />
                    </Slide>
                  </div>
                </div>

                <div className="story-col-b">
                  <div className="story-text">
                  <Slide triggerOnce duration={2000} direction='right'>
                    <label>OUR STORY</label>
                    <h3>The story of Las Casas Marina</h3>
                    <p>Destination Marina is a known for its picturesque coastal setting and vibrant atmosphere. </p>
                    <p>Located at the heart of Bagac Bataan, Las Casas Filipinas de Acuzar Destination Marina offers
                      a stunning view of the ocean and a range of activities for visitors to enjoy.
                      Las Casas Marina itself is a hub of activity, we offer exclusive inside access to Candaba Dos,
                      where you can enjoy the breath-taking view, pool area, 3 minutes complimentary massage for four
                      and private bar for purchase and enjoyment.
                    </p>
                    <p>You can take leisurely strolls along the marina, admiring the boats and soaking in the relaxing
                      atmosphere. Las Cas Marina is also home to a variety of shops, boutiques, and restaurants that line
                      the waterfront promenade. These establishments offer a diverse range of cuisines, from fresh food
                      delicacies to international flavors, catering to all tastes.
                    </p>
                    <Link to="boats"><button>Find a yacht</button></Link>
                  </Slide>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </>
  )
}

export default index