import React from 'react';
import Gallery from '../../components/GallerySection/Gallery'

function index() {
  return (
    <>
        <Gallery />
    </>
  )
}

export default index