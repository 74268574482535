import React from 'react';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import '../../styles/Gallery.css';
import galleryList from '../../data/gallery';

import lgZoom from 'lightgallery/plugins/zoom'

import { Fade } from 'react-awesome-reveal';
import {Zoom} from 'react-awesome-reveal';

function Gallery() {

    // const onInit = () => {
    //     console.log('light gal')
    // }

  const onBeforeSlide = (detail) => {
    const { index, prevIndex } = detail;
    console.log(index, prevIndex);
  };

  return (
    <>
        <div className="gallery-main">
            <Fade>
              <div className="gallery-cover">
                <img src="/images/coverpage/CoverC.png" alt="" />
                  <div className="gallery-text">
                    <Fade direction='down' triggerOnce>
                      <h1>GALLERY</h1>
                    </Fade>
                  </div>
              </div>
            </Fade>
            {/**GAL DESC */}
            <div className="gally-main-title">
              <div className="gally-cont">
                <Zoom triggerOnce='true'><img src="/images/logo/wtr.png" alt="" /></Zoom>
                <Zoom triggerOnce='true'><h3>Highlights from Marina</h3></Zoom>
                <Zoom triggerOnce='true'><p>Join us in this virtual voyage through our yacht gallery, and let your imagination set sail. Explore the possibilities, envision yourself aboard these magnificent vessels, and let us guide you towards making your yachting dreams a reality.</p></Zoom>
              </div>
            </div>

          {/**Main gallery */}
            <div className="gallery-wrap">
            {/**Gallery Col A */}
              <LightGallery
                elementClassNames="gallery"
                onBeforeSlide={onBeforeSlide}
                plugins={[lgZoom]}
                // onInit={onInit}
                download= {false}
                zoom={false}
                counter={false}
              >

                {galleryList.map((items, index) => (
                  <a href={items.boatImage} className='boat-img-col'>
                    <div className="image-card">
                      <Fade triggerOnce='true'>
                        <img src={items.boatImage} alt={items.boatLabel} />
                      </Fade>
                    </div>
                    <div className="image-label">
                      <p>{items.boatLabel}</p>
                    </div>
                  </a>
                ))}
              </LightGallery>

            </div>

        </div>
    </>
  )
}

export default Gallery
