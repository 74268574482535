import React from 'react';
import InquiryPage from '../../components/Inquiry/Inquiry';

function index() {
  return (
    <>
        <InquiryPage/>
    </>
  )
}

export default index