import React from 'react';
import '../../styles/Hero.css';
import { Link } from 'react-router-dom';
import { Zoom } from 'react-awesome-reveal';
import { Fade } from 'react-awesome-reveal';

function index() {
  return (
    <>
        <div className="contact-page">
            <div className="image-holder">
                <Fade><img src="/images/coverpage/conCov.png" alt="" /></Fade>
                <div className="contact-desc">
                    <div className="icon-holder">
                        <Zoom triggerOnce="true">
                            <img src="/images/logo/lcbbMarina.png" alt="" />
                        </Zoom>
                        <Zoom triggerOnce="true">
                            <h3>Start planning your next adventure on a private yacht. Contact us now to get started.</h3>
                        </Zoom>
                        <Zoom triggerOnce="true">
                            <p>Let us craft an exceptional experience that surpasses your expectations and fulfills your yachting dreams. Your next unforgettable adventure
                                awaits, and we are here to make it a reality.
                            </p>
                        </Zoom>

                        <Zoom triggerOnce="true">
                            <div className="contact-btn">
                                <Link to='/contact'><button className='contacts'>CONTACT US</button></Link>
                                <Link to='/membership' target='_blank'><button className='yachtForm'>YACHT PARTICULAR FORM</button></Link>
                            </div>
                        </Zoom>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default index