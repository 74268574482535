import React from 'react';
import ContactPage from '../../components/ContactSection/index'

function index() {
  return (
    <>
      <ContactPage />
    </>
  )
}

export default index