import React from 'react';
import '../../styles/About.css';

function index() {
  return (
    <>
        <div className="soon">
            <h1>Coming soon.</h1>
        </div>
    </>
  )
}

export default index