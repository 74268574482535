import React from 'react';
import '../../styles/Hero.css';
import { Fade, Slide } from 'react-awesome-reveal';
import { Link } from 'react-router-dom';

function destSection() {
  return (
    <>
        <div className="destination-section container-fluid">
            <div className="dest-row">
                    <div className="dest-col">
                        <div className="dest-text">
                          <Slide duration={2000} triggerOnce>
                            <span>WHO WE ARE</span>
                            <div className='wtr-icon'>
                                <img src="/images/logo/wtr.png" alt="" />
                            </div>
                            <h3>Reserve your dream yacht vacation today and indulge in an unparalleled escape like no other.</h3>
                            <p>Las Cas Marina is also home to a variety of shops, boutiques, and restaurants that line the waterfront promenade. These establishments offer a diverse range of cuisines, from fresh food delicacies to international flavors, catering to all tastes.</p>
                            <Link to="/about" className='destBtn' ><button>DISCOVER MORE</button></Link>
                          </Slide>
                        </div>
                    </div>

                    <div className="dest-col image-box">
                        <div className="des-image">
                          <Slide duration={2000} direction='right' triggerOnce>
                            <img src="/images/boat/cover.jpg" alt="" />
                          </Slide>
                        </div>
                    </div>
            </div>
      </div>
    </>
  )
}

export default destSection