import React, {useRef, useState} from 'react';
import '../../styles/member.css';
import {Zoom} from 'react-awesome-reveal';
import emailjs from '@emailjs/browser';
import {AiOutlineCheckCircle} from 'react-icons/ai';

function MemberForm() {

  //Modal message
  const [modal, setModal] = useState(false);

  const showModal = () => {
      setModal(!modal);
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_pczyp36', 'template_6kzz0ix', form.current, 'WFxWKyFtVWC1fEKeb')
        .then((result) => {
            // console.log(result.text);
            e.target.reset();
            //reseting the value after submit
            setUyacht("");
            setUowner("");
            setUlength("");
            setUbreadth("");
            setUdepth("");
            setUdraft("");
            setUdisplacement("");
            setUnettonnage("");
            setUgrosstonnage("");
            setUmainpropulsion("");
            setUfuela("");
            setUfuelcona("");
            setUpowergenerator("");
            setUfuelb("");
            setUfuelconb("");
            setUglasshull("");
            setUwoodenhull("");
            setUconcretehull("");
            setUsteelhull("");
            setUmainsail("");
            setUjibsail("");
            setUspinnakersail("");
            setUothersail("");
            setUcrewcabin("");
            setUguestscabin("");
            setUmastercabin("");
            setUothercabin("");
            setUfueltankcapacity("");
            setUwatertankcapacity("");
            setUcruisingspeed("");
            setUmaximumspeed("");
            setUdatebuild("");
            setUplacebuilt("");
            setUbuilderdesigner("");
            setUlastport("");
            setUlastport("");
            setUnextport("");
            setUcrewnumber("");
            // alert("success");
        }, (error) => {
            // console.log(error.text);
        });

  };

   //form value
    const [uyacht, setUyacht] = useState("");
    const [uowner, setUowner] = useState("");
    const [ulength, setUlength] = useState("");
    const [ubreadth, setUbreadth] = useState("");
    const [udepth, setUdepth] = useState("");
    const [udraft, setUdraft] = useState("");
    const [udisplacement, setUdisplacement] = useState("");
    const [unettonnage, setUnettonnage] = useState("");
    const [ugrosstonnage, setUgrosstonnage] = useState("");
    const [umainpropulsion, setUmainpropulsion] = useState("");
    const [ufuela, setUfuela] = useState("");
    const [ufuelcona, setUfuelcona] = useState("");
    const [upowergenerator, setUpowergenerator] = useState("");
    const [ufuelb, setUfuelb] = useState("");
    const [ufuelconb, setUfuelconb] = useState("");
    const [uglasshull, setUglasshull] = useState("");
    const [uwoodenhull, setUwoodenhull] = useState("");
    const [uconcretehull, setUconcretehull] = useState("");
    const [usteelhull, setUsteelhull] = useState("");
    const [umainsail, setUmainsail] = useState("");
    const [ujibsail, setUjibsail] = useState("");
    const [uspinnakersail, setUspinnakersail] = useState("");
    const [uothersail, setUothersail] = useState("");
    const [ucrewcabin, setUcrewcabin] = useState("");
    const [uguestscabin, setUguestscabin] = useState("");
    const [umastercabin, setUmastercabin] = useState("");
    const [uothercabin, setUothercabin] = useState("");
    const [ufueltankcapacity, setUfueltankcapacity] = useState("");
    const [uwatertankcapacity, setUwatertankcapacity] = useState("");
    const [ucruisingspeed, setUcruisingspeed] = useState("");
    const [umaximumspeed, setUmaximumspeed] = useState("");
    const [udatebuild, setUdatebuild] = useState("");
    const [uplacebuilt, setUplacebuilt] = useState("");
    const [ubuilderdesigner, setUbuilderdesigner] = useState("");
    const [udateandtime, setUdateandtime] = useState("");
    const [ulastport, setUlastport] = useState("");
    const [unextport, setUnextport] = useState("");
    const [ucaptain, setUcaptain] = useState("");
    const [ucrewnumber, setUcrewnumber] = useState("");

    //check if input form is filled
    const validate = () => {
        return uyacht.length && uowner.length && ulength.length && ubreadth.length && udepth.length && udraft.length &&
        udisplacement.length && unettonnage.length && ugrosstonnage.length && umainpropulsion.length && ufuela.length &&
        ufuelcona.length && upowergenerator.length && ufuelb.length && ufuelconb.length && uglasshull.length && uwoodenhull.length &&
        uconcretehull.length && usteelhull.length && umainsail.length && ujibsail.length && uspinnakersail.length && uothersail.length &&
        ucrewcabin.length && uguestscabin.length && umastercabin.length && uothercabin.length && ufueltankcapacity.length && uwatertankcapacity.length &&
        ucruisingspeed.length && umaximumspeed.length && udatebuild.length && uplacebuilt.length && ubuilderdesigner.length && udateandtime.length &&
        ulastport.length && unextport.length && ucaptain.length && ucrewnumber.length ;
    };

    //disable the button if the form is invali and enable if valid or filled
    //reset form

  return (
    <>
      {/** SUBMIT MODAL */}

      <div className={modal ? 'modal-wrap active' : 'modal-wrap'}>
        <div className="modal-box">
          <AiOutlineCheckCircle className='checkIcon' />
            <h1>Thank You!</h1>
            <p>Your details has been successfully <br/> submitted.</p>

            <div className="exit-btn">
                <button onClick={showModal} >OK</button>
            </div>
        </div>
      </div>

      <div className="member-wrap">
            <div className="member-title">
              <Zoom><h1>LAS CASAS MARINA YACHT CLUB</h1></Zoom>
              <Zoom><p>YACHT PARTICULARS</p></Zoom>
            </div>
            <div className="member-form">
              <form ref={form} onSubmit={sendEmail}>
              {/**col1 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="yacht">NAME OF YACHT:</label>
                    <input type="text" name="user_yacht" value={uyacht} onChange={(e) => setUyacht(e.target.value)} />
                  </div>

                  <div className="form-col">
                    <label htmlFor="owner">NAME OF OWNER:</label>
                    <input type="text" name="owner_name" value={uowner} onChange={(e) => setUowner(e.target.value)} />
                  </div>
                </div>

                {/**col2 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="length">LENGTH OVERALL:</label>
                    <input type="number" name="yacht_length" value={ulength} onChange={(e) => setUlength(e.target.value)} />
                  </div>

                  <div className="form-col">
                    <label htmlFor="breath">BREADTH MOULDED:</label>
                    <input type="number" name="yacht_breadth" value={ubreadth} onChange={(e) => setUbreadth(e.target.value)} />
                  </div>
                </div>

                {/**col3 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="depth">DEPTH MOULDED:</label>
                    <input type="number" name="yacht_depth" value={udepth} onChange={(e) => setUdepth(e.target.value)} />
                  </div>

                  <div className="form-col">
                    <label htmlFor="draft">DRAFT:</label>
                    <input type="text" name="yacht_draft" value={udraft} onChange={(e) => setUdraft(e.target.value)} />
                  </div>
                </div>

                {/**col4 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="displacement">DISPLACEMENT:</label>
                    <input type="text" name="yacht_displacement" value={udisplacement} onChange={(e) => setUdisplacement(e.target.value)} placeholder='tons' />
                  </div>

                  <div className="form-col">
                    <label htmlFor="tonnage">NET TONNAGE:</label>
                    <input type="number" name="yacht_nettonnage" value={unettonnage} onChange={(e) => setUnettonnage(e.target.value)} />
                  </div>
                </div>

                {/**col5 */}
                <div className="form-row-b">
                  <div className="form-col">
                    <label htmlFor="yacht_grosstonnage">GROSS TONNAGE:</label>
                    <input type="number" name="yacht_grosstonnage" value={ugrosstonnage} onChange={(e) => setUgrosstonnage(e.target.value)} placeholder='tons' />
                  </div>
                </div>

                {/**col6 */}
                <div className="form-row-c">
                  <div className="form-col">
                    <label htmlFor="yacht_mainpropulsion">MAIN PROPULSION:</label>
                    <input type="number" name="yacht_mainpropulsion" value={umainpropulsion} onChange={(e) => setUmainpropulsion(e.target.value)} placeholder='units x  hp  Engine'/>
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_fuela">FUEL:</label>
                    <input type="text" name="yacht_fuela" value={ufuela} onChange={(e) => setUfuela(e.target.value)} />
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_fuelcona">FUEL CONSUMPTION:</label>
                    <input type="text" name="yacht_fuelcona" value={ufuelcona} onChange={(e) => setUfuelcona(e.target.value)} placeholder='ltrs/hr/engine' />
                  </div>
                </div>

                {/**col7 */}
                <div className="form-row-c">
                  <div className="form-col">
                    <label htmlFor="yacht_powergenerator">POWER GENERATOR:</label>
                    <input type="text" name="yacht_powergenerator" value={upowergenerator} onChange={(e) => setUpowergenerator(e.target.value)} placeholder='kws driven by " Engine'/>
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_fuelb">FUEL:</label>
                    <input type="text" name="yacht_fuelb" value={ufuelb} onChange={(e) => setUfuelb(e.target.value)} />
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_fuelconb">FUEL CONSUMPTION:</label>
                    <input type="text" name="yacht_fuelconb" value={ufuelconb} onChange={(e) => setUfuelconb(e.target.value)} placeholder='ltrs/hr/engine' />
                  </div>
                </div>

                {/**col8 */}
                <div className="form-cabin">
                  <p>Hull Construction</p>
                  <div className="form-row-d">
                    <div className="form-col">
                      <label htmlFor="yacht_glasshull">F/GLASS HULL:</label>
                      <input type="text" name="yacht_glasshull" value={uglasshull} onChange={(e) => setUglasshull(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_woodenhull">WOODEN HULL:</label>
                      <input type="text" name="yacht_woodenhull" value={uwoodenhull} onChange={(e) => setUwoodenhull(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_concretehull">CONCRETE HULL:</label>
                      <input type="text" name="yacht_concretehull" value={uconcretehull} onChange={(e) => setUconcretehull(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_steelhull">STEEL HULL:</label>
                      <input type="text" name="yacht_steelhull" value={usteelhull} onChange={(e) => setUsteelhull(e.target.value)} />
                    </div>
                  </div>
                </div>

                 {/**col9 */}
                 <div className="form-cabin">
                  <p>Yacht Sails</p>
                  <div className="form-row-d">
                    <div className="form-col">
                      <label htmlFor="yacht_mainsail">Main Sail</label>
                      <input type="text" name="yacht_mainsail" value={umainsail} onChange={(e) => setUmainsail(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_jibsail">JIB SAIL:</label>
                      <input type="text" name="yacht_jibsail" value={ujibsail} onChange={(e) => setUjibsail(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_spinnakersail">SPINNAKER SAIL:</label>
                      <input type="text" name="yacht_spinnakersail" value={uspinnakersail} onChange={(e) => setUspinnakersail(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_othersail">OTHER SAIL:</label>
                      <input type="text" name="yacht_othersail" value={uothersail} onChange={(e) => setUothersail(e.target.value)} />
                    </div>
                  </div>
                </div>

                {/**col10 */}
                <div className="form-cabin">
                  <p>No. of Cabins</p>
                  <div className="form-row-d">
                    <div className="form-col">
                      <label htmlFor="yacht_crewcabin">Crew Cabin:</label>
                      <input type="number" name="yacht_crewcabin" value={ucrewcabin} onChange={(e) => setUcrewcabin(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_guestscabin">GUESTS CABIN:</label>
                      <input type="number" name="yacht_guestscabin" value={uguestscabin} onChange={(e) => setUguestscabin(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_matercabin">MASTER CABIN:</label>
                      <input type="number" name="yacht_mastercabin" value={umastercabin} onChange={(e) => setUmastercabin(e.target.value)} />
                    </div>

                    <div className="form-col">
                      <label htmlFor="yacht_othercabin">OTHER CABIN:</label>
                      <input type="text" name="yacht_othercabin" value={uothercabin} onChange={(e) => setUothercabin(e.target.value)} />
                    </div>
                  </div>
                </div>

                {/**col11 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="yacht_fueltankcapacity">FUEL TANK CAPACITY:</label>
                    <input type="text" name="yacht_fueltankcapacity" value={ufueltankcapacity} onChange={(e) => setUfueltankcapacity(e.target.value)} placeholder='liters'/>
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_watertankcapacity">WATER TANK CAPACITY:</label>
                    <input type="text" name="yacht_watertankcapacity" value={uwatertankcapacity} onChange={(e) => setUwatertankcapacity(e.target.value)} placeholder='liters' />
                  </div>
                </div>

                {/**col12 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="yacht_cruisingspeed">CRUISING SPEED:</label>
                    <input type="text" name="yacht_cruisingspeed" value={ucruisingspeed} onChange={(e) => setUcruisingspeed(e.target.value)} placeholder='knots' />
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_maximumspeed">MAXIMUM SPEED:</label>
                    <input type="text" name="yacht_maximumspeed" value={umaximumspeed} onChange={(e) => setUmaximumspeed(e.target.value)} placeholder='knots' />
                  </div>
                </div>

                {/**col13 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="yacht_datebuild">DATE BUILD:</label>
                    <input type="date" name="yacht_datebuild" value={udatebuild} onChange={(e) => setUdatebuild(e.target.value)} placeholder='knots' />
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_placebuilt">PLACE BUILT:</label>
                    <input type="text" name="yacht_placebuilt" value={uplacebuilt} onChange={(e) => setUplacebuilt(e.target.value)} />
                  </div>
                </div>

                {/**col14 */}
                <div className="form-row-b">
                  <div className="form-col">
                    <label htmlFor="yacht_builderdesigner">BUILDER DESIGNER:</label>
                    <input type="text" name="yacht_builderdesigner" value={ubuilderdesigner} onChange={(e) => setUbuilderdesigner(e.target.value)} />
                  </div>
                </div>

                {/**col15 */}
                <div className="form-row-b">
                  <div className="form-col">
                    <label htmlFor="yacht_dateandtime">DATE AND TIME OF ARRIVAL:</label>
                    <input type="text" name="yacht_dateandtime" value={udateandtime} onChange={(e) => setUdateandtime(e.target.value)} placeholder='Hrs , 2016' />
                  </div>
                </div>

                {/**col16 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="yacht_lastport">LAST PORT:</label>
                    <input type="text" name="yacht_lastport" value={ulastport} onChange={(e) => setUlastport(e.target.value)} />
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_nextport">NEXT PORT:</label>
                    <input type="text" name="yacht_nextport" value={unextport} onChange={(e) => setUnextport(e.target.value)} />
                  </div>
                </div>

                {/**col17 */}
                <div className="form-row">
                  <div className="form-col">
                    <label htmlFor="yacht_captain">NAME OF YACHT CAPTAIN:</label>
                    <input type="text" name="yacht_captain" value={ucaptain} onChange={(e) => setUcaptain(e.target.value)} />
                  </div>

                  <div className="form-col">
                    <label htmlFor="yacht_ucrewnumber">TOTAL NO. OF CREW:</label>
                    <input type="number" name="yacht_crewnumber" value={ucrewnumber} onChange={(e) => setUcrewnumber(e.target.value)} />
                  </div>
                </div>

                <div className="marina-btn">
                  <button type='submit' className='submit-member' onClick={showModal} disabled={!validate()} >SUBMIT</button>
                </div>

              </form>
            </div>
        </div>
    </>
  )
}

export default MemberForm