const galleryList = [
    {
        boatImage: '/images/gallery/1.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/2.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/3.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/5.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/6.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/7.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/8.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/9.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/10.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/11.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/12.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/13.jpg',
        boatLabel: "CASA CANDABA II"
    },

    {
        boatImage: '/images/gallery/14.jpg',
        boatLabel: "CASA CANDABA II"
    },

    // {
    //     boatImage: '/images/gallery/15.jpg',
    //     boatLabel: "PARTY BOAT"
    // },

    // {
    //     boatImage: '/images/gallery/16.jpg',
    //     boatLabel: "PERSONNELS"
    // },

    {
        boatImage: '/images/gallery/17.jpg',
        boatLabel: "TOWABLES"
    },

    {
        boatImage: '/images/gallery/19.jpg',
        boatLabel: "TOWABLES"
    },

    // {
    //     boatImage: '/images/gallery/20.jpg',
    //     boatLabel: "PERSONNELS"
    // },

    {
        boatImage: '/images/gallery/21.jpg',
        boatLabel: "PERSONNELS"
    },

    {
        boatImage: '/images/gallery/22.jpg',
        boatLabel: "PERSONNELS"
    },

    {
        boatImage: '/images/gallery/23.jpg',
        boatLabel: "PERSONNELS"
    },
]

export default galleryList