import React from 'react';
import About from '../../components/AboutSection/index';
import ContactSec from '../../components/Contact/ContactSection'

function index() {
  return (
    <>
        <About/>
        <ContactSec />
    </>
  )
}

export default index