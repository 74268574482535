import React, {useState} from 'react';
import '../../styles/Boats.css';
import {IoMdClose} from 'react-icons/io';
import {RiRuler2Line} from 'react-icons/ri';
import {BsSpeedometer} from 'react-icons/bs';
import {HiOutlineUserGroup} from 'react-icons/hi';
import '../../styles/Offer.css';
import { Link } from 'react-router-dom';
import { Fade } from 'react-awesome-reveal';
import {Zoom} from 'react-awesome-reveal';

function BoatWp() {

    const [isBoatModal, setIsBoatModal] = useState(false);
    const [isBoatModalA, setIsBoatModalA] = useState(false);
    const [isBoatModalB, setIsBoatModalB] = useState(false);
    const [isBoatModalC, setIsBoatModalC] = useState(false);
    const [isBoatModalD, setIsBoatModalD] = useState(false);
    const [isBoatModalE, setIsBoatModalE] = useState(false);

    const showBoat = () => {
        setIsBoatModal(!isBoatModal);
    }

    const showBoatA = () => {
        setIsBoatModalA(!isBoatModalA);
    }

    const showBoatB = () => {
        setIsBoatModalB(!isBoatModalB);
    }

    const showBoatC = () => {
        setIsBoatModalC(!isBoatModalC);
    }

    const showBoatD = () => {
        setIsBoatModalD(!isBoatModalD);
    }

    const showBoatE = () => {
        setIsBoatModalE(!isBoatModalE);
    }

  return (
    <>
     {/**MODAL */}
        <div className={isBoatModal ? 'package-wrap active' : 'package-wrap'}>
            <div className="close-modal">
                <button onClick={showBoat} ><IoMdClose className='closeIcon' /></button>
            </div>
            <div className="package-box">
                <div className="package-details">
                    <img src="/images/yacht/package/endlessSummer.png" alt="" />
                </div>
            </div>
        </div>

        <div className={isBoatModalA ? 'package-wrap active' : 'package-wrap'}>
            <div className="close-modal">
                <button onClick={showBoatA} ><IoMdClose className='closeIcon' /></button>
            </div>
            <div className="package-box">
                <div className="package-details">
                    <img src="/images/yacht/package/tiara.png" alt="" />
                </div>
            </div>
        </div>

        <div className={isBoatModalB ? 'package-wrap active' : 'package-wrap'}>
            <div className="close-modal">
                <button onClick={showBoatB} ><IoMdClose className='closeIcon' /></button>
            </div>
            <div className="package-box">
                <div className="package-details">
                    <img src="/images/yacht/package/swiss.png" alt="" />
                </div>
            </div>
        </div>

        <div className={isBoatModalC ? 'package-wrap active' : 'package-wrap'}>
            <div className="close-modal">
                <button onClick={showBoatC} ><IoMdClose className='closeIcon' /></button>
            </div>
            <div className="package-box">
                <div className="package-details">
                    <img src="/images/yacht/package/angela.png" alt="" />
                </div>
            </div>
        </div>

        <div className={isBoatModalD ? 'package-wrap active' : 'package-wrap'}>
            <div className="close-modal">
                <button onClick={showBoatD} ><IoMdClose className='closeIcon' /></button>
            </div>
            <div className="package-box">
                <div className="package-details">
                    <img src="/images/yacht/package/sailboat.png" alt="" />
                </div>
            </div>
        </div>

        <div className={isBoatModalE ? 'package-wrap active' : 'package-wrap'}>
            <div className="close-modal">
                <button onClick={showBoatE} ><IoMdClose className='closeIcon' /></button>
            </div>
            <div className="package-box">
                <div className="package-details">
                    <img src="/images/yacht/package/speedboat.png" alt="" />
                </div>
            </div>
        </div>

        {/**MAIN */}

        <div className="boat-main">
            <Fade>
                <div className="bt-cover">
                    <img src="/images/coverpage/CoverB.png" alt="" />
                        <div className="bt-text">
                            <Fade direction='down' triggerOnce>
                                <h1>BOATS</h1>
                            </Fade>
                        </div>
                </div>
            </Fade>

            <div className="container-fluid boat-page">
                <div className="boat-tag">
                    <Zoom triggerOnce='true'><img src="/images/logo/wtr.png" alt="" /></Zoom>
                    <Zoom triggerOnce='true'><h3>Elevate your on-water adventures.</h3></Zoom>
                    <Zoom triggerOnce='true'><p>Immerse yourself in the luxury of choice, and let the anticipation of your upcoming
                    yacht charter adventure fill your sails with excitement. Contact us now, and let the voyage of a lifetime unfold before you.</p></Zoom>
                </div>

                <div className="row boat-row">

                        <div className="col-md-4 col-12">
                            <div className="boat-box">
                                <Zoom direction='down' duration={1000} triggerOnce="true">
                                    <div className='boat-handler'>
                                        <img src="/images/boat/EndlessSummer2.png" alt="" />
                                        {/**hidden btns */}
                                        <div className="boat-btn">
                                            <div className='btn-abslt'>
                                                <div className='btn-wrp'><Link><button onClick={showBoat} className='specs-btn'>VIEW SPECS</button></Link></div>
                                                <div className='btn-wrp'><Link to="/inquiry"><button className='book-btn'>BOOK NOW</button></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                    <h5>ENDLESS SUMMER</h5>
                                    <h4>BAYLINER PILOTHOUSE 4788</h4>
                                    <p>Our Bayliner Yacht is a masterpiece of design, elegance, and engineering, from the moment you step on board, you'll be immersed in a world of refined luxury and impeccable craftsmanship. </p>

                                    <hr></hr>

                                    <div className="spec-details">
                                        <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>54-footer</p></div>
                                        <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>12-15 knots</p></div>
                                        <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>25 pax</p></div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="boat-box">
                                <Zoom direction='down' duration={1000} triggerOnce="true">
                                    <div className='boat-handler'>
                                        <img src="/images/boat/TIARA DAMAFINO.jpg" alt="" />
                                        {/**hidden btns */}
                                        <div className="boat-btn">
                                            <div className='btn-abslt'>
                                                <div className='btn-wrp'><Link><button onClick={showBoatA} className='specs-btn'>VIEW SPECS</button></Link></div>
                                                <div className='btn-wrp'><Link to="/inquiry"><button className='book-btn'>BOOK NOW</button></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                    <h5>
                                        DAMIFINO III
                                    </h5>
                                    <h4>TIARA 36 OPEN</h4>
                                    <p>Prepare to experience yachting excellence like never before with our Tiara Damafino, is a vessel that perfectly embodies the precise blend of performance, and comfort. </p>

                                    <hr></hr>

                                    <div className="spec-details">
                                        <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>36-footer</p></div>
                                        <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>17-25 knots</p></div>
                                        <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>15 pax</p></div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="boat-box">
                                <Zoom direction='down' duration={1000} triggerOnce="true">
                                    <div className='boat-handler'>
                                        <img src="/images/boat/SWISS BANKER.png" alt="" />
                                        {/**hidden btns */}
                                        <div className="boat-btn">
                                            <div className='btn-abslt'>
                                                <div className='btn-wrp'><Link><button onClick={showBoatB} className='specs-btn'>VIEW SPECS</button></Link></div>
                                                <div className='btn-wrp'><Link to="/inquiry"><button className='book-btn'>BOOK NOW</button></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                    <h5>
                                        SWISS BANKER
                                    </h5>
                                    <h4>BAYLINER 360</h4>
                                    <p>Whether you're fishing, cruising, or simply enjoying a day out with family and friends, the Swiss Banker offers a perfect balance of comfort, functionality, and performance.</p>

                                    <hr></hr>

                                    <div className="spec-details">
                                        <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>36-footer</p></div>
                                        <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>25-32 knots</p></div>
                                        <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>15 pax</p></div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                </div>

                <div className="row boat-row">

                        <div className="col-md-4 col-12">
                            <div className="boat-box">
                                <Zoom direction='down' duration={1000} triggerOnce="true">
                                    <div className='boat-handler'>
                                        <img src="/images/boat/ANGELA CLAIRE.png" alt="" />
                                        {/**hidden btns */}
                                        <div className="boat-btn">
                                            <div className='btn-abslt'>
                                                <div className='btn-wrp'><Link><button onClick={showBoatC} className='specs-btn'>VIEW SPECS</button></Link></div>
                                                <div className='btn-wrp'><Link to="/inquiry"><button className='book-btn'>BOOK NOW</button></Link></div>
                                            </div>
                                        </div>
                                    </div>

                                    <h5>
                                        SUNDANCER 260
                                    </h5>
                                    <h4>ANGELA CLAIRE</h4>
                                    <p>Experience the thrill of speed and agility as you navigate the waterways with Angela Claire. Equipped with powerful engines and advanced hull design allowing you to explore with confidence.</p>

                                    <hr></hr>

                                    <div className="spec-details">
                                        <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>26-footer</p></div>
                                        <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>20-30 knots</p></div>
                                        <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>8 pax</p></div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="boat-box">
                                <Zoom direction='down' duration={1000} triggerOnce="true">
                                    <div className='boat-handler'>
                                        <img src="/images/boat/Saiboat2.png" alt="" />
                                        {/**hidden btns */}
                                        <div className="boat-btn">
                                            <div className='btn-abslt'>
                                                <div className='btn-wrp'><Link><button onClick={showBoatD} className='specs-btn'>VIEW SPECS</button></Link></div>
                                                <div className='btn-wrp'><Link to="/inquiry"><button className='book-btn'>BOOK NOW</button></Link></div>
                                            </div>
                                        </div>
                                    </div>

                                    <h5>
                                        CATALINA 22
                                    </h5>
                                    <h4>SAILBOAT</h4>
                                    <p>Welcome to the world of adventure and tranquility on our exceptional sailboat! Prepare to set sail on a remarkable journey where the wind guides your course and the open sea becomes your playground. </p>

                                    <hr></hr>

                                    <div className="spec-details">
                                        <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>22-footer</p></div>
                                        <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>Wind-dependent</p></div>
                                        <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>6 pax</p></div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>

                        <div className="col-md-4 col-12">
                            <div className="boat-box">
                                <Zoom direction='down' duration={1000} triggerOnce="true">
                                    <div className='boat-handler'>
                                        <img src="/images/boat/speedBoat2.png" alt="" />
                                        {/**hidden btns */}
                                        <div className="boat-btn">
                                            <div className='btn-abslt'>
                                                <div className='btn-wrp'><Link><button onClick={showBoatE} className='specs-btn'>VIEW SPECS</button></Link></div>
                                                <div className='btn-wrp'><Link to="/inquiry"><button className='book-btn'>BOOK NOW</button></Link></div>
                                            </div>
                                        </div>
                                    </div>
                                    <h5>
                                        BLUE MAVERICK
                                    </h5>
                                    <h4>SPEEDBOAT</h4>
                                    <p>Are you ready for an adrenaline-pumping adventure on the water? Look no further than the Las casa Marina Ultimate Speed Boat, a vessel designed to satisfy your need for speed and excitement. 
                                    </p>

                                    <hr></hr>

                                    <div className="spec-details">
                                        <div className="specs-col"><RiRuler2Line className='boatIcon'/><p>22-footer</p></div>
                                        <div className="specs-col"><BsSpeedometer className='boatIcon' /><p>30 knots</p></div>
                                        <div className="specs-col"><HiOutlineUserGroup className='boatIcon' /><p>6 pax</p></div>
                                    </div>
                                </Zoom>
                            </div>
                        </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default BoatWp