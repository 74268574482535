import React from 'react';
import Video from 'react-responsive-video';
import '../../styles/Hero.css';
import MainVids from '../../vid/marinaVid3.mp4';
import { Fade } from 'react-awesome-reveal';

function Hero() {

  return (
    <>
      <div className="container-fluid">
        <div className="row">
            <div className="cover">
              <Fade>
                <Video className="hero-vid" mp4={MainVids} />
              </Fade>
            </div>
          </div>
      </div>
    </>
  )
}

export default Hero