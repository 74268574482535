import React, {useRef, useState} from 'react';
import '../../styles/Contact.css';
import {Fade, Slide} from 'react-awesome-reveal';
import emailjs from '@emailjs/browser';
import {AiOutlineCheckCircle} from 'react-icons/ai';

function Index() {

  //Modal message
  const [modal, setModal] = useState(false);

  const showModal = () => {
      setModal(!modal);
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_n2d2x0r', 'template_frmn3tp', form.current, 'WFxWKyFtVWC1fEKeb')
        .then((result) => {
            // console.log(result.text);
            e.target.reset();
            //reseting the value after submit
            setUname("");
            setUemail("");
            setUphone("");
            setUdate("");
            setUboat("");
            setUpax("");
            setUmessage("");
            // alert("success");
        }, (error) => {
            // console.log(error.text);
        });

  };

   //form value
    const [uname, setUname] = useState("");
    const [uemail, setUemail] = useState("");
    const [uphone, setUphone] = useState("");
    const [udate, setUdate] = useState("");
    const [uboat, setUboat] = useState("");
    const [upax, setUpax] = useState("");
    const [umessage, setUmessage] = useState("");

    //check if input form is filled
    const validate = () => {
        return uname.length && uemail.length && uphone.length && udate.length && uboat.length && upax.length && umessage.length ;
    };

    //disable the button if the form is invali and enable if valid or filled
    //reset form

  return (
    <>
      {/** SUBMIT MODAL */}

      <div className={modal ? 'modal-wrap active' : 'modal-wrap'}>
        <div className="modal-box">
          <AiOutlineCheckCircle className='checkIcon' />
            <h1>Thank You!</h1>
            <p>Your details has been successfully <br/> submitted.</p>

            <div className="exit-btn">
                <button onClick={showModal} >OK</button>
            </div>
        </div>
      </div>

       {/**MAIN */}

       <div className="contact-main">
       <Fade triggerOnce>
         <div className="contact-cover">
           <img src="/images/coverpage/CoverE.jpg" alt="" />
             <div className="contact-text">
               <Fade direction='down' triggerOnce>
                 <h1>CONTACT US</h1>
               </Fade>
             </div>
         </div>
       </Fade>

       <div className="contact-wrap">
         <img className='water-icon' src="/images/logo/wtr.png" alt="" />

         <Fade triggerOnce>
           <div className="contact-title">
             <span>YACHT SERVICES</span>
             <h1>FEEL FREE TO CONTACT US</h1>
             <p>Embark on smooth sailing! Contact us today to dock your dreams and set sail for unforgettable maritime adventures.</p>
           </div>
         </Fade>

         <Fade triggerOnce>
           <div className="contact-map">
             <div className="map-frame">
               <iframe iframe title='myFrame' src="https://maps.google.com/maps?q=victoria%20sport%20tow&t=&z=13&ie=UTF8&iwloc=&output=embed" width="100%" height="400" allowfullscreen="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
             </div>
           </div>
         </Fade>

         {/**contact details */}
         <div className="contact-row">
           <Fade triggerOnce>
             <div className="contact-col">
               <img src="/images/icons/mail.png" alt="" />
               <div className="contact-info">
                 <h4>Email us</h4>
                 <p>info@lascasasmarina.com</p>
               </div>
             </div>
           </Fade>

           <Fade triggerOnce>
             <div className="contact-col">
               <img src="/images/icons/location.png" alt="" />
               <div className="contact-info">
                 <h4>Las Casas Filipinas de Acuzar</h4>
                 <p>Brgy. Ibaba, Bagac Bataan 2107, Philippines</p>
               </div>
             </div>
           </Fade>

           <Fade triggerOnce>
             <div className="contact-col">
               <img src="/images/icons/phone.png" alt="" />
               <div className="contact-info">
                 <h4>Call us</h4>
                 <p>+63963-441-5023 or +63921-888-8222</p>
               </div>
             </div>
           </Fade>
         </div>
       </div>

       <Fade triggerOnce>
         <div className="email-wrap">
             <div className="email-row">
               <div className="email-col">
                 <h1>SUBSCRIBE TO NEWSLETTER</h1>
                 <p>Stay afloat with exclusive updates and tidal treasures! Be the first to catch wind of special offers, boating tips, and upcoming marina events.</p>
               </div>

               <div className="email-col email-sub">
                  <form className='email-formss' action="https://lascasasfilipinas.us1.list-manage.com/subscribe/post?u=2dc85a100274ce1d29cb7076c&amp;id=81f43e7f29" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate="">
                    <input type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="Type you email..." required />
                    <input type="submit" value="SUBSCRIBE" name="subscribe" id="mc-embedded-subscribe" className='button-news' />
                  </form>
               </div>

             </div>
         </div>
       </Fade>

       {/**BOOKING WRAP */}
       <div className="booking-wrap">
          <div className="booking-row">
            <Slide direction='left' triggerOnce>
            <div className="booking-col">
            <Fade><h1>BOOKING</h1></Fade>
              <form ref={form} onSubmit={sendEmail} className="inq-form">
                  <div className="row">
                    <div className="col-md-6 col-12 form-input">
                      <input type="text" name="user_name" value={uname} onChange={(e) => setUname(e.target.value)} placeholder='Name' />
                    </div>

                    <div className="col-md-6 col-12 form-input">
                      <input type="email" name="user_email" value={uemail} onChange={(e) => setUemail(e.target.value)} placeholder='Email' />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-12 form-input">
                      <input type="number" name="user_phone" value={uphone} onChange={(e) => setUphone(e.target.value)} placeholder='Phone number' />
                    </div>

                    <div className="col-md-6 col-12 form-input">
                      <input type="date" name="user_date" value={udate} onChange={(e) => setUdate(e.target.value)} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 col-12 form-input">
                      <select name="boat_type" value={uboat} onChange={(e) => setUboat(e.target.value)} >
                        <option value="Bayliner Pilothouse">Bayliner Pilothouse</option>
                        <option value="Tiara Damifino">Tiara Damifino</option>
                        <option value="Swiss Banker">Swiss Banker</option>
                        <option value="Angela Claire">Sailboat</option>
                        <option value="Speed Boat">Speed Boat</option>
                      </select>
                    </div>

                    <div className="col-md-6 col-12 form-input">
                      <input type="number" name="pax_num" value={upax} onChange={(e) => setUpax(e.target.value)} placeholder='No. of pax' />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12 col-12 form-input-message">
                      <textarea name="user_message" id="message" cols="30" rows="5" value={umessage} onChange={(e) => setUmessage(e.target.value)} placeholder='Additional Request'></textarea>
                    </div>
                  </div>

                  <div className="submit-booking">
                    <button type='submit' onClick={showModal} disabled={!validate()} >SUBMIT</button>
                  </div>
              </form>
            </div>
            </Slide>

            <Slide direction='right' triggerOnce>
              <div className="booking-col">
                <img src="/images/yacht/booking.jpg" alt="" />
              </div>
            </Slide>
          </div>
       </div>



     </div>
    </>
  )
}

export default Index