import React from 'react';
import '../../styles/Destinations.css';
import { Fade, Slide, Zoom } from 'react-awesome-reveal';

function DestinationSec() {

  return (
    <div className="destination-main">
        <Fade>
            <div className="dest-cover">
                <img src="/images/coverpage/CoverA.jpg" alt="" />
                    <div className="dest-text">
                        <Fade direction='down' triggerOnce>
                            <h1>DESTINATIONS</h1>
                        </Fade>
                    </div>
            </div>
        </Fade>

        <div className="destination-list">
                <div className="dest-list">
                    <div className="dest-img">
                        <Slide triggerOnce='true'><img src='/images/destination/Bar.png' alt="" /></Slide>

                        <div className="dest-details">
                            <Slide direction='right' triggerOnce='true'>
                                <h3>PRIVATE BAR ACCESS</h3>
                                <p>Take a moment to prioritize your well-being and treat yourself with our free
                                    blissful 30-minute spa massage. Experience the rejuvenating benefits of expert
                                    touch, tailored techniques, and a tranquil environment that will transport you
                                    to a place of serenity.</p>
                            </Slide>
                        </div>
                    </div>

                    <div className="dest-img order-2-dest">
                        <div className="dest-details">
                            <Slide triggerOnce>
                                <h3>MASSAGE FOR 30 MINUTES</h3>
                                <p>Take a moment to prioritize your well-being and treat yourself with our
                                    free blissful 30-minute spa massage. Experience the rejuvenating benefits
                                    of expert touch, tailored techniques, and a tranquil environment that will
                                    transport you to a place of serenity.
                                </p>
                            </Slide>
                        </div>
                        <Slide direction='right' triggerOnce>
                            <img src='/images/destination/Massage.png' alt="" />
                        </Slide>
                    </div>

                    <div className="dest-img">
                        <Slide triggerOnce ><img src='/images/destination/Pool.png' alt="" /></Slide>

                        <div className="dest-details">
                            <Slide direction='right' triggerOnce>
                                <h3>SWIMMING POOL EXCLUSIVE ACCESS</h3>
                                <p>Exclusivity is of paramount importance to us. Our private pool ensures
                                    that you can enjoy your aquatic sanctuary without any disturbances. Indulge
                                    in a swim, soak up the sun on comfortable loungers, or simply relax by the
                                    poolside in complete privacy.
                                </p>
                            </Slide>
                        </div>
                    </div>

                    <div className="dest-img order-2-dest">
                        <div className="dest-details">
                            <Slide triggerOnce>
                                <h3>10% ON WATER SPORTS RENTALS</h3>
                                <p>Enjoy our 10% discount in all our Water sports rentals. </p>
                            </Slide>
                        </div>
                        <Slide direction='right' triggerOnce>
                            <img src='/images/destination/WaterSports.png' alt="" />
                        </Slide>
                    </div>
                </div>

            {/**DESTINATION DESCRIPTION */}
            <Zoom triggerOnce>
                <div className="access-details">
                    <h1>PERKS AND EXCLUSIVE MEMBERSHIP ACCESS</h1>
                    <ul>
                        <li>Destination Marina is not just a marina; it's a destination that captures the essence of waterfront living at its finest. With its prime location, state-of-the-art facilities, vibrant waterfront community, and emphasis on sustainability, it promises an unforgettable experience for visitors and an enticing investment opportunity. Join us in realizing the vision of Destination Marina, where dreams set sail, memories are made, and an extraordinary coastal lifestyle awaits.</li>
                        <li>Become a part of the Las Casas Destination Marina community by joining our exclusive membership program. </li>
                        <li>As a member, you'll gain access to a host of unparalleled benefits, privileges, and experiences, allowing you to fully immerse yourself in the vibrant waterfront lifestyle. </li>
                        <li>From premier amenities and personalized services to a sense of belonging within our thriving marina community, a Destination Marina membership offers a gateway to unforgettable experiences and endless opportunities.</li>
                    </ul>
                </div>
            </Zoom>

        </div>

    </div>
  )
}

export default DestinationSec