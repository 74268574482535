import React from 'react';
import MoreBoats from '../../components/OurBoats/BoatWp';

function index() {
  return (
    <>
        <MoreBoats />
    </>
  )
}

export default index